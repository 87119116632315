@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&display=swap');

html, body{
  overflow-x: hidden;
  width: 100%;
}

.nunito{
  font-family: 'Nunito', sans-serif;
}

.tab[aria-selected="true"]{
  color: black;
  border-color: transparent;
  background-color: white;
}

.montserrat{
  font-family: 'Montserrat', sans-serif;
}

.fade-out {
  animation: fadeOut 1s forwards;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}


/* Text Fade Out Animation */
.callout-exit {
  position: relative;
  animation: calloutFade 1s forwards;
  opacity: 1;
  transform-origin: center center;
  transform-style: preserve-3d;
}

@keyframes calloutFade {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }
  100% {
    opacity: 0;
    transform: translateZ(-200px);
  }
}

.shadow-overlay{
  text-shadow: 16px 24px 2px 0px rgba(0, 0, 0, 0.25);
}

.textShadowAnimate{
  
}

@keyframes shadowFade {
  0% {
    text-shadow: 16px 24px 2px 0px rgba(0, 0, 0, 0.25);
  }
  100% {
    text-shadow: 16px 24px 18px 0px rgba(0, 0, 0, 0.25);
  }
}